/** @format */

import Base from "@/api-services/base.service";
import * as msal from "@azure/msal-browser";
import Usuarios from "@/api-services/usuarios.service";

export default {
  loginRequest: {
    scopes: [
      //   "User.Read"
      "openid",
      "profile",
    ],
  },
  config: {
    auth: {
      clientId: "e824bb05-c61f-43d2-b08e-b7a902b42336",
      /*    tenantId: "953fdba4-4022-47df-b33d-27e8708522e1",*/
      tenantName: "common",
      // tenantName: "eclockad",
      //authority: 'https://login.microsoftonline.com/common/',
      authority: Base.AuthorityURL,

      knownAuthorities: ["https://eclockad.b2clogin.com"],

      autoRefreshToken: true,
      //navigateToLoginRequestUrl: true,
      navigateToLoginRequestUrl: false,
      //redirectUri: "http://localhost:8080/?ad=1",
      redirectUri: Base.AuthURL,
      postLogoutRedirectUri: `${Base.AuthURL}/tickets`,
      /*   onToken: (ctx, error, response) => {
            console.debug(response);
          },*/
    },
    mode: "redirect",
    cache: {
      cacheLocation: "localStorage",
    },
  },

  msalInstance: null,
  inicializa() {
    this.msalInstance = new msal.PublicClientApplication(this.config);
    this.msalInstance.initialize();
    /*  console.debug(this.msalInstance.getAllAccounts());
    console.debug(this.msalInstance);*/
  },
  handleRedirectPromise(next, rutaDestino) {
    if (rutaDestino && !rutaDestino.includes("/dashboards/landing") && rutaDestino.indexOf("state=") < 0)
      localStorage.rutaDestino = rutaDestino;
    else {
      if (localStorage.rutaDestino) {
        console.debug(localStorage.rutaDestino);
        next(localStorage.rutaDestino);
        localStorage.removeItem("rutaDestino"); // Limpiar después de la redirección
      }
      return;
    }
    var msalT = this.msalInstance;
    msalT.handleRedirectPromise().then((response) => {
      var ElNext = next;
      if (response !== null) {
        var accountId = response.account.homeAccountId;
        console.debug(accountId);

        this.ActualizaToken(ElNext);
        //ElNext();
        // Display signed-in user content, call API, etc.
      } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = msalT.getAllAccounts();
        console.debug(currentAccounts);
        if (currentAccounts.length === 0) {
          // no accounts signed-in, attempt to sign a user in
          msalT.loginRedirect(this.loginRequest);
        } else {
          this.ActualizaToken(ElNext);
        }
      }
    });
  },
  CierraSesion() {
    // Cerrar sesión con redirección
    this.msalInstance
      .logoutRedirect()
      .then(() => {
        console.log("Sesión cerrada");
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async login() {
    try {
      var response = await this.msalInstance.loginRedirect(this.loginRequest);
      console.debug(response);
    } catch (error) {
      console.error(error);
    }
  },
  isTokenExpired(token) {
    var json = atob(token.split(".")[1]);
    const expiry = JSON.parse(json).exp;
    const Fecha = Math.floor(new Date().getTime() / 1000);
    return Fecha >= expiry;
  },

  ActualizaToken(ElNext) {
    var msalT = this.msalInstance;
    var ElToken = localStorage.sesion;
    if (ElToken) {
      if (!this.isTokenExpired(ElToken)) {
        //ElNext();
        if (typeof ElNext === "function") {
          ElNext();
        } else {
          console.debug(typeof ElNext);
          console.debug(ElNext);
        }

        return;
      }
    }
    console.debug("ActualizandoToken");
    const currentAccounts = msalT.getAllAccounts();
    var request = {
      scopes: [
        "openid",
        "profile",
        //  "User.Read"
      ],
      account: currentAccounts[0], // will default to CacheLookupPolicy.Default if omitted
    };
    msalT
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        console.debug("ActualizaToken");
        localStorage.sesion = tokenResponse.idToken;
        localStorage.EsSSO = 1;

        Usuarios.getPerfilLocal(() => {
          var rutaDestino = localStorage.rutaDestino;
          console.debug(rutaDestino);
          ElNext(); /*
          if (rutaDestino) {
            ElNext(rutaDestino);
            //     localStorage.rutaDestinoAnterior = rutaDestino;
            localStorage.removeItem("rutaDestino"); // Limpiar después de la redirección
          } else {
            ElNext(); // Continuar con la ruta por defecto si no hay ruta deseada
          }*/
        });
        //ElNext();
      })
      .catch((error) => {
        try {
          console.error(error);
          if (error.errorCode == "InteractionRequired") {
            return msalT.acquireTokenRedirect(request);
          } else if (error instanceof msal.InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalT.acquireTokenRedirect(request);
          }
        } catch {
          console.error(error);
          return msalT.acquireTokenRedirect(request);
        }
      });
  },
};
